// noinspection DuplicatedCode

import { createSelector } from '@ngrx/store';
import moment from 'moment';
import { AuthSelectors } from 'src/app/auth';
import {
  mapPinMarkerWithCustomLabelToFeature,
  mapRoutePolylineToFeature,
  mapRouteToPickUpPolylineToFeature,
  mapVehicleToFeature,
} from 'src/app/ol-map/mappings/ol-map-mappings';
import { LiveMapLayer, LiveMapLayerType, OlMapFeature } from 'src/app/ol-map/models/live-map.vm.model';
import {
  getBookingStatus,
  getBookingStatusColor,
  getJobPaymentMethodType,
  getPaymentMethodType,
  getRefundOperationType,
  mapCompensationsToCompensationVMList,
  mapExternalFeesToExternalFeesVM,
  mapPaymentRefundsToPaymentRefundVMList,
} from 'src/app/shared/mappings/mappings';
import { AccountUser } from 'src/app/shared/models/account-user.model';
import {
  Booking,
  BookingAggregate,
  BookingJob,
  BookingPaymentMethodType,
  BookingTrip,
  CancellationReason,
  Claim,
  DriverTip,
  DriverWait,
  Note,
  Offer,
  OfferStatusType,
  OperationType,
  Payment,
  PriceType,
  StopRoute,
} from 'src/app/shared/models/booking.model';
import { BookingPaymentRefundVM, CompensationVM } from 'src/app/shared/models/booking.vm.model';
import { MetaOp } from 'src/app/shared/models/entity.model';
import { OriginType, RefundOperationType, RefundType } from 'src/app/shared/models/enums.model';
import { ExternalFee } from 'src/app/shared/models/external-fees.model';
import { FirebaseUser } from 'src/app/shared/models/firebase-user.model';
import { Location } from 'src/app/shared/models/location.model';
import { ProductFee, Quote } from 'src/app/shared/models/quote.model';
import {
  DateTimeHelpers,
  formatDuration,
  formatPickUpAtDuration,
  formatWaitTimeFeeDuration,
} from 'src/app/shared/utils/dateTime.helpers';
import {
  calculateListTotalAmount,
  convertDigitaxNumberToDecimalNumber,
  getMeteredOrQuoteTripPrice,
  getRideType,
  toNegative,
} from 'src/app/shared/utils/payment.utils';
import { formatDiscount } from 'src/app/shared/utils/string-formatting';
import {
  getBookingPaymentMethodType,
  getBookingPaymentMethodVariant,
  getCancellationFeeReason,
  getCancellationOriginTypeName,
  getCancellationReason,
  getDriverTitle,
  getLocationAddress,
  getNetAmountLabelText,
  getOperationType,
  getOriginTypeName,
  getPaymentOperationSource,
  getPosTerminalType,
  getPriceType,
  mapQueryParamsToPage,
  mapQueryParamsToSearchForm,
  showBookingDriverTelemetry,
} from '../../shared/mappings/mappings';
import { Area } from '../../shared/models/area.model';
import { Business } from '../../shared/models/business.model';
import { DispatchTag } from '../../shared/models/dispatch-tag.model';
import { DriverAggregate, DriverEta, DriverTelemetry } from '../../shared/models/driver.model';
import { SearchResponse } from '../../shared/models/elastic-api.model';
import { PartnerCompanyAggregate } from '../../shared/models/partner.model';
import { ProductFeature } from '../../shared/models/product-feature.model';
import { RiderAggregate } from '../../shared/models/rider.module';
import { TripRiderDetailsVM } from '../../shared/models/rider.vm.model';
import {
  NoteVM,
  ProductFeeVM,
  TripClaimVM,
  TripDetailsVM,
  TripDriverDetailsVM,
  TripListDriverVM,
  TripListVM,
  TripLogVM,
  TripOfferVM,
  TripPartnerDetailsVM,
  TripPaymentInformationVM,
  TripPaymentOperationVM,
  TripPaymentVM,
  TripVehicleDetailsVM,
  TripsSearchResponse,
  Waypoint,
} from '../../shared/models/trips.vm.model';
import { VehicleAggregate, VehicleType } from '../../shared/models/vehicle.model';
import { FirestoreSelector } from '../../store/firestore';
import { SupportAgentState } from '../../store/support-agent.state';

export const tripsState = (state: SupportAgentState) => state.tripsState;

export const isUserSignedIn = createSelector(AuthSelectors.isLoggedInSelector, isSignedIn => isSignedIn);

export const userAreaIdsSelector = createSelector(AuthSelectors.userAreaIdsSelector, areaIds => areaIds);

export const storagePageSizeSelector = createSelector(
  AuthSelectors.browserStorageSelector,
  storage => storage?.find(item => item.key === 'pageSize:supportAgent:trips')?.value,
);

export const autoRefreshStorageSelector = createSelector(
  AuthSelectors.browserStorageSelector,
  storage => storage?.find(item => item.key === 'autoRefresh:supportAgent:trips')?.value,
);

export const areasSelector = createSelector(FirestoreSelector.areaListSelector, areas => areas);

export const allProductFeatureListSelector = createSelector(
  FirestoreSelector.productFeatureListSelector,
  features => features,
);
export const productFeatureListSelector = createSelector(FirestoreSelector.productFeatureListSelector, features =>
  features.filter(feature => feature.meta_op !== MetaOp.DELETE),
);

export const allExternalFeesListSelector = createSelector(
  FirestoreSelector.externalFeesListSelector,
  externalFees => externalFees,
);
export const externalFeesListSelector = createSelector(FirestoreSelector.externalFeesListSelector, externalFees =>
  externalFees.filter(fee => fee.meta_op !== MetaOp.DELETE),
);

export const allDispatchTagsSelector = createSelector(FirestoreSelector.dispatchTagsSelector, tags => tags);
export const dispatchTagsSelector = createSelector(FirestoreSelector.dispatchTagsSelector, tags =>
  tags.filter(tag => tag.meta_op !== MetaOp.DELETE),
);

export const vehicleTypesSelector = createSelector(
  FirestoreSelector.vehicleTypesSelector,
  vehicleTypes => vehicleTypes,
);

export const tripIdSelector = createSelector(tripsState, state => state.bookingId);

export const bookingAggregateSelector = createSelector(tripsState, state => state.bookingAggregate);

export const bookingsSelector = createSelector(tripsState, state => state.bookings);

export const queryParamsSelector = createSelector(tripsState, state => state.queryParams);

export const userPageSizeSelector = createSelector(tripsState, state => state.userPageSize);

export const searchResponseSelector = createSelector(tripsState, state => state.searchResponse);

export const isLoadingSelector = createSelector(tripsState, state => state.isLoading);

export const isModalLoadingSelector = createSelector(tripsState, state => state.isModalLoading);

export const driversSelector = createSelector(tripsState, state => state.drivers);

export const driverAggregateSelector = createSelector(tripsState, state => state.driverAggregate);

export const driverUserSelector = createSelector(tripsState, state => state.driverUser);

export const vehiclesSelector = createSelector(tripsState, state => state.vehicles);

export const vehicleAggregateSelector = createSelector(tripsState, state => state.vehicleAggregate);

export const businessSelector = createSelector(tripsState, state => state.business);

export const firebaseUsersSelector = createSelector(tripsState, state => state.users);

export const quoteSelector = createSelector(tripsState, state => state.quote);

export const riderSelector = createSelector(tripsState, state => state.rider);

export const claimDriversSelector = createSelector(tripsState, state => state.claimsDrivers);

export const driverTelemetrySelector = createSelector(tripsState, state => state.driverTelemetry);

export const driverEtaSelector = createSelector(tripsState, state => state.driverEta);

export const autoRefreshStateSelector = createSelector(tripsState, state => state.autoRefresh);

export const partnerSelector = createSelector(tripsState, state => state.partner);

export const notCompletedTripListDriversSelector = createSelector(bookingsSelector, bookings =>
  bookings ? mapTripListIntoNotCompletedTripListDriversVM(bookings) : [],
);

function mapTripListIntoNotCompletedTripListDriversVM(bookings: BookingAggregate[]): TripListDriverVM[] {
  return [
    ...new Set(
      bookings
        .filter(aggregate => !aggregate.trip)
        .map(aggregate => {
          const driverId = aggregate.job?.driver_id;
          const vehicleId = aggregate.job?.vehicle_id;
          const claims =
            aggregate.claims?.length > 1
              ? [...aggregate.claims].sort((a, b) => (a.meta_ts > b.meta_ts ? -1 : 1))
              : aggregate.claims;
          const latestClaim = claims ? claims[0] : null;
          if (driverId && vehicleId) {
            return {
              driverId,
              vehicleId,
              bookingId: aggregate.id,
            };
          } else {
            if (latestClaim?.driver_id) {
              return {
                driverId: latestClaim.driver_id,
                bookingId: aggregate.id,
              };
            }
          }
        }),
    ),
  ].filter(drivers => !!drivers);
}

export const bookingListSelector = createSelector(
  bookingsSelector,
  areasSelector,
  notCompletedTripListDriversSelector,
  driversSelector,
  vehiclesSelector,
  (bookings, areas, tripListDrivers, drivers, vehicles) =>
    bookings ? mapBookingsToTripList(bookings, areas, tripListDrivers, drivers, vehicles) : null,
);

function mapBookingsToTripList(
  bookings: BookingAggregate[],
  areas: Area[],
  tripListDrivers: TripListDriverVM[],
  drivers: DriverAggregate[],
  vehicles: VehicleAggregate[],
): TripListVM[] {
  return bookings
    .map(aggregate => {
      const booking = aggregate.booking;
      const trip = aggregate.trip;
      const tripPrice = aggregate.trip_price;

      const pickup =
        trip && trip.pickup
          ? getLocationAddress(trip.pickup)
          : booking && booking.pickup
            ? getLocationAddress(booking.pickup)
            : '';

      const dropoff =
        trip && trip.dropoff
          ? getLocationAddress(trip.dropoff)
          : booking && booking.dropoff
            ? getLocationAddress(booking.dropoff)
            : '';

      let driverFullName = getDriverFullName(trip, tripListDrivers, drivers, aggregate);
      let vehicleLicensePlate = getVehicleLicensePlate(trip, tripListDrivers, vehicles, aggregate);

      const status = getBookingStatus(aggregate);
      const timeZoneId = mapBookingAggrToAreaTimeZone(areas, booking?.area_id);
      const currentTime = moment().tz(timeZoneId)?.format(); // TODO move to date format service or utils
      const duration = moment.duration(moment(booking?.pickup_at).diff(currentTime)).asMinutes();

      return {
        bookingId: aggregate.id,
        createdAt: booking?.created_at,
        pickupAt: booking?.pickup_at,
        pickup,
        dropoff,
        driverFullName,
        vehicleLicensePlate,
        totalPrice: tripPrice?.customer_net_amount?.display,
        priceType: tripPrice ? getPriceType(tripPrice.type) : '',
        timeZoneId,
        status,
        rating: aggregate.driver_rating?.rating,
        statusColorClass: getBookingStatusColor(status),
        timeLeftPickupAt: duration > 0 ? formatPickUpAtDuration(duration) : null,
      };
    })
    .sort((a, b) => b.createdAt - a.createdAt);
}

export const autoRefreshSelector = createSelector(
  autoRefreshStateSelector,
  autoRefreshStorageSelector,
  (state, storage) => (!state ? (!storage ? false : storage) : state),
);

export const bookingAggregateCompensationsSelector = createSelector(
  bookingAggregateSelector,
  aggregate => aggregate?.compensations,
);

export const bookingAggregatePaymentRefundsSelector = createSelector(
  bookingAggregateSelector,
  aggregate => aggregate?.payment_refunds,
);

export const bookingAggregateDriverTipRefundsSelector = createSelector(
  bookingAggregateSelector,
  aggregate => aggregate?.driver_tip_refunds,
);

export const bookingAggregatePaymentSelector = createSelector(
  bookingAggregateSelector,
  aggregate => aggregate?.payment,
);

export const bookingAggregateBookingSelector = createSelector(
  bookingAggregateSelector,
  aggregate => aggregate?.booking,
);

export const bookingAggregateTripSelector = createSelector(bookingAggregateSelector, aggregate => aggregate?.trip);

export const bookingAggregateOffersSelector = createSelector(bookingAggregateSelector, aggregate => aggregate?.offers);

export const bookingAggregateStopRoutesSelector = createSelector(
  bookingAggregateSelector,
  aggregate => aggregate?.stop_routes,
);

export const bookingAggregateClaimsSelector = createSelector(bookingAggregateSelector, aggregate => aggregate?.claims);

export const bookingAggregateJobSelector = createSelector(bookingAggregateSelector, aggregate => aggregate?.job);

export const quoteIdSelector = createSelector(bookingAggregateBookingSelector, booking => booking?.quote_id);

export const riderIdSelector = createSelector(bookingAggregateBookingSelector, booking => booking?.rider_id);

export const tripCompensationsSelector = createSelector(bookingAggregateCompensationsSelector, compensations =>
  compensations ? mapCompensationsToCompensationVMList(compensations) : null,
);

export const tripPaymentRefundsSelector = createSelector(bookingAggregatePaymentRefundsSelector, paymentRefunds =>
  paymentRefunds ? mapPaymentRefundsToPaymentRefundVMList(paymentRefunds) : null,
);

export const tripDriverTipRefundsSelector = createSelector(
  bookingAggregateDriverTipRefundsSelector,
  driverTipRefunds => (driverTipRefunds ? mapPaymentRefundsToPaymentRefundVMList(driverTipRefunds) : null),
);

export const tripPaymentSelector = createSelector(bookingAggregatePaymentSelector, payment =>
  payment ? mapBookingAggrToTripPayments(payment) : null,
);

export const driverTipSelector = createSelector(bookingAggregateSelector, aggregate => aggregate?.driver_tip);

export const tripOffersSelector = createSelector(
  tripIdSelector,
  bookingAggregateOffersSelector,
  driversSelector,
  (bookingId, offers, drivers) => (offers ? mapBookingAggrOffersToTripOffers(bookingId, offers, drivers) : null),
);

export const tripOfferDriverIdsSelector = createSelector(bookingAggregateOffersSelector, offers =>
  offers ? mapBookingAggrOffersToDriverIds(offers) : null,
);

export const tripAcceptedOfferSelector = createSelector(bookingAggregateOffersSelector, offers =>
  offers?.length > 0 ? offers.find(m => m.status.type === OfferStatusType.ACCEPTED) : null,
);

export const tripOfferAcceptedDriverIdSelector = createSelector(tripAcceptedOfferSelector, offer => offer?.driver_id);

export const tripAcceptedOfferVehicleIdSelector = createSelector(tripAcceptedOfferSelector, offer => offer?.vehicle_id);

export const tripDriverIdSelector = createSelector(bookingAggregateTripSelector, trip => trip?.driver?.id);

export const tripVehicleIdSelector = createSelector(bookingAggregateTripSelector, trip => trip?.vehicle?.id);

export const businessIdSelector = createSelector(bookingAggregateBookingSelector, booking => booking?.business_id);

export const partnerCompanyIdSelector = createSelector(
  bookingAggregateTripSelector,
  trip => trip?.partner?.id, // TODO rename after migration
);

export const claimsSelector = createSelector(bookingAggregateClaimsSelector, claims =>
  claims?.length > 1 ? [...claims].sort((a, b) => (a.meta_ts > b.meta_ts ? -1 : 1)) : claims,
);

export const tripLastClaimSelector = createSelector(claimsSelector, claims => (claims ? claims[0] : null));

export const tripClaimsDriverIdsSelector = createSelector(claimsSelector, claims => [
  ...new Set(claims?.map(claim => claim.driver_id).filter(d => d != null)),
]);

export const tripClaimsVMSelector = createSelector(claimsSelector, claimDriversSelector, (claims, drivers) =>
  claims && drivers ? mapClaimToClaimVM(claims, drivers) : null,
);

function mapClaimToClaimVM(claims: Claim[], drivers: DriverAggregate[]): TripClaimVM[] {
  return claims.map(claim => ({
    claimedAt: claim.meta_ts,
    driver: getDriverTitle(drivers.find(d => d.id === claim.driver_id)),
    driverId: claim.driver_id,
  }));
}

export const bookingMapFeaturesSelector = createSelector(
  bookingAggregateBookingSelector,
  bookingAggregateTripSelector,
  bookingAggregateStopRoutesSelector,
  (booking, trip, stopRoutes) => mapTripDetailsToMap(booking, trip, stopRoutes),
);

function mapTripDetailsToMap(booking: Booking, trip: BookingTrip, stopRoutes: StopRoute[]): OlMapFeature[] {
  const features: OlMapFeature[] = [];

  if (booking?.pickup) {
    features.push(mapLocationToOlMapFeature(booking.pickup, 'RP'));
  }

  if (booking?.dropoff) {
    features.push(mapLocationToOlMapFeature(booking.dropoff, 'RD'));
  }

  if (trip?.pickup) {
    features.push(mapLocationToOlMapFeature(trip.pickup, 'AP'));
  }

  if (trip?.dropoff) {
    features.push(mapLocationToOlMapFeature(trip.dropoff, 'AD'));
  }

  if (booking?.waypoints?.length > 0) {
    booking.waypoints.forEach(point => {
      features.push(mapLocationToOlMapFeature(point, 'RWP'));
    });
  }

  if (trip?.waypoints?.length > 0) {
    trip.waypoints.forEach(point => {
      features.push(mapLocationToOlMapFeature(point, 'AWP'));
    });
  }

  if (trip?.polyline) {
    features.push(
      mapRoutePolylineToFeature({
        id: 'route',
        polyline: trip.polyline,
      }),
    );
  }

  if (stopRoutes?.length > 0) {
    const routeToPickup = [...stopRoutes].sort((a, b) => (a.completed_at < b.completed_at ? -1 : 1))[0];
    features.push(
      mapRouteToPickUpPolylineToFeature({
        id: 'routeToPickup',
        polyline: routeToPickup.polyline,
      }),
    );
  }
  return features;
}

function mapLocationToOlMapFeature(location: Location, label: string = ''): OlMapFeature {
  return mapPinMarkerWithCustomLabelToFeature({
    label,
    id: getLocationAddress(location), //TODO check
    coordinate: {
      lat: location.lat,
      lng: location.lng,
    },
  });
}

export const driverMapFeaturesSelector = createSelector(
  driverTelemetrySelector,
  driverEtaSelector,
  bookingAggregateJobSelector,
  (telemetry, eta, job) => mapDriverDetailsToMapLayerFeatures(telemetry, eta, job),
);

function mapDriverDetailsToMapLayerFeatures(
  telemetry: DriverTelemetry,
  driverEta: DriverEta,
  job: BookingJob,
): OlMapFeature[] {
  const features: OlMapFeature[] = [];
  if (telemetry) {
    features.push(
      mapVehicleToFeature({
        id: 'vehicle',
        coordinate: {
          lat: telemetry.latitude,
          lng: telemetry.longitude,
        },
        status: job.in_progress_at === null && driverEta ? 'Accepted' : 'Default',
        rotation: (telemetry.direction * Math.PI) / 180,
        eta: job.in_progress_at === null && driverEta ? formatDriverEta(driverEta.eta) : null,
      }),
    );
  }
  return features;
}

export const mapLayerSelector = createSelector(
  bookingMapFeaturesSelector,
  driverMapFeaturesSelector,
  (bookingMapFeatures, driverMapFeatures) => getMapLayer(bookingMapFeatures, driverMapFeatures),
);

function getMapLayer(bookingMapFeatures: OlMapFeature[], driverMapFeatures: OlMapFeature[]): LiveMapLayer[] {
  return [
    {
      type: LiveMapLayerType.DEFAULT,
      selectable: false,
      title: 'trip-details',
      features: [...bookingMapFeatures, ...driverMapFeatures],
    },
  ];
}

export const firebaseUserIdsSelector = createSelector(
  tripPaymentRefundsSelector,
  tripCompensationsSelector,
  tripDriverTipRefundsSelector,
  (paymentRefunds, compensations, tipRefunds) => mapUserActionsToUserIds(paymentRefunds, compensations, tipRefunds),
);

function mapUserActionsToUserIds(
  paymentRefunds: BookingPaymentRefundVM[],
  compensations: CompensationVM[],
  tipRefund: BookingPaymentRefundVM[],
): string[] {
  const ids = [];
  paymentRefunds?.forEach(m => ids.push(m.createdBy));
  compensations?.forEach(m => ids.push(m.createdBy));
  tipRefund?.forEach(m => ids.push(m.createdBy));
  return ids.filter((v, i, a) => a.indexOf(v) === i && !!v);
}

export const failedToCollectAtPaymentSelector = createSelector(bookingAggregateSelector, aggregate =>
  aggregate ? mapFailedToCollectAtPayment(aggregate) : null,
);

function mapFailedToCollectAtPayment(aggregate: BookingAggregate): number {
  const booking = aggregate.booking;
  const bookingJob = aggregate.job;
  if (aggregate.booking?.origin_type === OriginType.ORIGIN_TYPE_DRIVER_APP) {
    const isPaymentFailed =
      booking &&
      bookingJob &&
      booking.payment_method === null &&
      bookingJob.completed_at !== null &&
      bookingJob.payment_method === null;
    return isPaymentFailed ? bookingJob.completed_at : null;
  } else {
    const isPaymentFailed =
      booking &&
      bookingJob &&
      (booking.payment_method === null ||
        booking.payment_method?.type === BookingPaymentMethodType.PAYMENT_METHOD_TYPE_IN_PERSON) &&
      booking.rider_id === null &&
      bookingJob.completed_at !== null &&
      bookingJob.ended_at !== null &&
      bookingJob.payment_method === null;
    return isPaymentFailed ? bookingJob.completed_at : null;
  }
}

export const paymentsLogSelector = createSelector(
  tripPaymentSelector,
  driverTipSelector,
  tripPaymentRefundsSelector,
  tripCompensationsSelector,
  tripDriverTipRefundsSelector,
  firebaseUsersSelector,
  failedToCollectAtPaymentSelector,
  (payment, driverTip, paymentRefunds, compensations, tipRefunds, users, failedCollectPaymentAt) =>
    mapBookingAggrToLog(payment, driverTip, paymentRefunds, compensations, tipRefunds, users, failedCollectPaymentAt),
);

export const tripLogSelector = createSelector(
  paymentsLogSelector,
  tripOffersSelector,
  tripClaimsVMSelector,
  bookingAggregateJobSelector,
  (paymentsLog, offers, claims, job) => mapLog(paymentsLog, offers, claims, job),
);

function mapLog(paymentsLog: TripLogVM[], offers: TripOfferVM[], claims: TripClaimVM[], job: BookingJob): TripLogVM[] {
  const fullLog = [];
  paymentsLog?.forEach(log => fullLog.push(log));
  offers?.forEach(offer => fullLog.push(formatOfferLog(offer)));
  claims?.forEach(claim => fullLog.push(formatClaimLog(claim)));
  if (job && job.cancelled_at != null && job.cancellation?.reason != null) {
    fullLog.push(formatJobLog(job));
  }
  return fullLog.sort((n1, n2) => (n1.date > n2.date ? -1 : 1));
}

export const tripDetailsSelector = createSelector(
  bookingAggregateSelector,
  areasSelector,
  allProductFeatureListSelector,
  allExternalFeesListSelector,
  businessSelector,
  vehicleTypesSelector,
  tripLastClaimSelector,
  quoteSelector,
  (aggregate, areas, productFeatures, externalFees, business, vehicleTypes, claim, quote) =>
    aggregate
      ? mapBookingAggrToTripDetails(
          aggregate,
          areas,
          productFeatures,
          externalFees,
          business,
          vehicleTypes,
          claim,
          quote,
        )
      : null,
);

export const isTripDetailsLoadedSelector = createSelector(
  isUserSignedIn,
  tripDetailsSelector,
  (isSignedIn, tripDetails) => isSignedIn && tripDetails !== null,
);

function mapBookingAggrToTripDetails(
  aggregate: BookingAggregate,
  areas: Area[],
  productFeatures: ProductFeature[],
  externalFees: ExternalFee[],
  business: Business,
  vehicleTypes: VehicleType[],
  claim?: Claim,
  quote?: Quote,
): TripDetailsVM {
  const booking = aggregate.booking;
  const trip = aggregate.trip;
  const tripPrice = aggregate?.trip_price;
  const job = aggregate.job;
  const arrivals = aggregate.driver_waits;
  const timeZoneId = mapBookingAggrToAreaTimeZone(areas, booking.area_id);

  const requestedPickup = getLocationAddress(booking.pickup);
  const requestedDropoff = getLocationAddress(booking.dropoff);
  const actualPickup = getLocationAddress(trip?.pickup);
  const actualDropOff = getLocationAddress(trip?.dropoff);

  const bookingExternalFees = tripPrice?.external_fees || quote?.external_fees;

  return {
    timeZoneId,
    createdAt: booking.created_at,
    pickupAt: booking.pickup_at,
    claimedAt: claim && claim.driver_id ? claim.meta_ts : null,
    acceptedAt: job ? job.accepted_at : trip?.accepted_at,
    inProgressAt: job ? job.in_progress_at : trip?.in_progress_at,
    pickupLatestAt: booking.pickup_latest_at,
    arrivedAt: arrivals?.length > 0 ? getArrivalAt(arrivals) : null,
    endedAt: job ? job.ended_at : trip?.ended_at,
    completedAt: job?.completed_at,
    cancelledAt: booking.cancelled_at,
    cancellationOriginType: getCancellationOriginTypeName(booking.cancellation_origin_type),
    cancellationReason: getCancellationReason(booking.cancellation_reason),
    expiredAt: booking.expired_at,
    originalPickup: requestedPickup,
    originalDropoff: requestedDropoff,
    actualPickup,
    actualDropoff: actualDropOff,
    pickup: trip?.pickup ? actualPickup : requestedPickup,
    dropoff: trip?.dropoff ? actualDropOff : requestedDropoff,
    waypoints: mapWaypoints(booking.waypoints, trip?.waypoints),
    polyline: trip?.polyline,
    originTypeName: getOriginTypeName(booking.origin_type),
    duration: formatDuration(trip?.duration),
    distance: formatDistance(trip?.distance),
    riderId: booking?.rider_id ?? trip?.rider?.id,
    notes: booking?.notes ? mapBookingNotesToNotesVM(booking?.notes) : null,
    businessId: booking?.business_id,
    businessName: business?.name,
    rating: aggregate?.driver_rating?.rating,
    partnerId: aggregate?.trip?.partner.id,
    vehicleType:
      booking?.vehicle_type_id && vehicleTypes?.length > 0
        ? vehicleTypes.find(m => m.id === booking.vehicle_type_id).name
        : null,
    features:
      booking?.feature_ids?.length > 0
        ? booking.feature_ids.map(id => {
            const feature = productFeatures.find(m => m.id === id);
            return {
              name: feature?.name,
              icon: feature?.icon,
            };
          })
        : [],
    externalFees: mapExternalFeesToExternalFeesVM(bookingExternalFees, externalFees),
    rideType: getRideType(booking.work_trip),
    expenseNote: booking.expense_note,
  };
}

export const tripPaymentInformationSelector = createSelector(
  bookingAggregateSelector,
  allProductFeatureListSelector,
  allExternalFeesListSelector,
  quoteSelector,
  (aggregate, productFeatures, externalFees, quote) =>
    aggregate ? mapBookingAggrToTripPaymentInformation(aggregate, productFeatures, externalFees, quote) : null,
);

function mapBookingAggrToTripPaymentInformation(
  aggregate: BookingAggregate,
  productFeatures: ProductFeature[],
  externalFees: ExternalFee[],
  quote: Quote,
): TripPaymentInformationVM {
  const booking = aggregate.booking;
  const job = aggregate.job;
  const trip = aggregate.trip;
  const tripPrice = aggregate.trip_price;
  const driverTip = aggregate.driver_tip;
  const payment = aggregate.payment;
  const compensations = aggregate.compensations;
  const taximeterTrip = aggregate.digitax_trip;
  const completedDriverTipRefunds = aggregate.driver_tip_refunds?.filter(
    tip => tip.completed_at !== null && tip.failed_at == null,
  );
  const completedPaymentRefunds = aggregate.payment_refunds?.filter(
    refund => refund.completed_at !== null && refund.failed_at == null && refund.type === RefundType.TRANSPORTATION,
  );
  const completedExternalFeePaymentRefunds = aggregate.payment_refunds?.filter(
    refund => refund.completed_at !== null && refund.failed_at == null && refund.type === RefundType.EXTERNAL_FEES,
  );
  const cancellationFee = trip?.cancellation?.fee;
  const waitTimeFees = aggregate.wait_time_fees;

  const bookingExternalFees = quote?.external_fees ?? tripPrice?.external_fees;

  if (!quote && trip?.quote) {
    quote = trip.quote;
  }

  const type = booking?.payment_method?.type
    ? getBookingPaymentMethodType(booking.payment_method.type)
    : job?.payment_method?.type
      ? getJobPaymentMethodType(job?.payment_method)
      : null;

  const variant = booking?.payment_method?.variant
    ? getBookingPaymentMethodVariant(booking.payment_method.variant)
    : job?.payment_method?.variant
      ? getBookingPaymentMethodVariant(job.payment_method.variant)
      : null;

  const chargedPaymentOperations = payment?.operations.filter(op => op.type === OperationType.CHARGED);

  return {
    priceType: tripPrice ? getPriceType(tripPrice.type) : null,
    bookingPaymentMethodType: type,
    bookingPaymentMethodVariant: type !== variant ? variant : null,
    currency: tripPrice?.customer_net_amount?.currency,

    //Cancellation
    cancellationFeeLabelText: getNetAmountLabelText(tripPrice?.type),
    cancellationFeeReason: cancellationFee ? getCancellationFeeReason(cancellationFee.reason) : null,

    // Manual price
    manualPrice: booking.manual_price?.display,
    // Metered total price
    meteredTotalPrice:
      tripPrice?.type === PriceType.METERED && convertDigitaxNumberToDecimalNumber(taximeterTrip?.total_amount),

    // Quote
    quoteTotalPrice: quote?.discount?.base_price ? quote?.discount?.base_price.display : quote?.total_price.display,
    quoteFlatRatePrice: quote?.flat_rate?.amount.display,
    quoteTariffStartingPrice: getMeteredOrQuoteTripPrice(
      tripPrice?.type,
      taximeterTrip?.initial_amount,
      quote?.tariff_price?.starting_price.display,
    ),
    quoteTariffDistancePrice: getMeteredOrQuoteTripPrice(
      tripPrice?.type,
      taximeterTrip?.amount_from_distance,
      quote?.tariff_price?.distance_price.display,
    ),
    quoteTariffDurationPrice: getMeteredOrQuoteTripPrice(
      tripPrice?.type,
      taximeterTrip?.amount_from_time,
      quote?.tariff_price?.duration_price.display,
    ),
    quoteFees: mapProductFeeToProductFeeVM(quote?.fees, productFeatures),
    quoteMinimumAmountFee: quote?.minimum_amount_fee?.fee_amount.display,

    // Waiting time fees
    waitTimeFees: waitTimeFees?.map(fee => ({
      amount: fee.amount.display,
      duration: formatWaitTimeFeeDuration(fee.duration),
    })),

    // Trip price
    basePrice: tripPrice?.base_price?.display,
    netAmountText: tripPrice ? getNetAmountLabelText(tripPrice.type) : 'Net amount',
    netAmount: tripPrice?.customer_net_amount?.display,
    discountByOrganizationToCustomer: formatDiscount(tripPrice?.discount_by_organization_to_customer?.amount?.display),
    discountByPartnerToCustomer: formatDiscount(tripPrice?.discount_by_partner_to_customer?.amount?.display),
    partnerNetRevenue: tripPrice?.partner_net_revenue?.display,
    kickbackDiscount: formatDiscount(tripPrice?.kickback_discount_by_partner_to_business?.amount?.display),
    externalFees: mapExternalFeesToExternalFeesVM(bookingExternalFees, externalFees),
    partnerGrossRevenue: tripPrice?.partner_gross_revenue?.display,

    // Payment
    showPaymentInformation:
      chargedPaymentOperations?.length > 0 || !!driverTip?.amount?.value || compensations?.length > 0,
    paymentTotalAmountValue: calculateListTotalAmount(chargedPaymentOperations, item => item.amount.value),
    totalPaymentRefund: toNegative(calculateListTotalAmount(completedPaymentRefunds, item => item.amount.value)),
    totalExternalFeesPaymentRefund: toNegative(
      calculateListTotalAmount(completedExternalFeePaymentRefunds, item => item.amount.value),
    ),

    // Tip
    tipTotalAmountValue: driverTip?.amount?.value,
    totalTipPaymentRefund: toNegative(calculateListTotalAmount(completedDriverTipRefunds, item => item.amount.value)),

    // Compensation
    totalCompensationsAmountValue: calculateListTotalAmount(compensations, item => item.amount.value),

    // Invoice
    isSendReceiptEnabled: !!(
      booking?.payment_method?.type === BookingPaymentMethodType.PAYMENT_METHOD_TYPE_INVOICE ||
      payment?.total_amount.display
    ),
  };
}

export const profileSelector = createSelector(bookingAggregateSelector, aggregate =>
  aggregate?.booking?.work_trip === true ? 'Work' : 'Personal',
);

export const showBookingDriverTelemetrySelector = createSelector(bookingAggregateSelector, aggregate =>
  aggregate ? showBookingDriverTelemetry(aggregate) : false,
);

export const tripDriverDetailsSelector = createSelector(
  driverAggregateSelector,
  allDispatchTagsSelector,
  driverUserSelector,
  (aggregate, tags, user) => (aggregate ? mapDriverAggregateToDriverDetails(aggregate, tags, user) : null),
);

function mapDriverAggregateToDriverDetails(
  aggregate: DriverAggregate,
  tags: DispatchTag[],
  user: AccountUser,
): TripDriverDetailsVM {
  return {
    id: aggregate.id,
    email: user?.email,
    title: getDriverTitle(aggregate),
    taxiLicenseNumber: aggregate.driver.taxi_license_number,
    phone: aggregate.driver.phone,
    dispatchTags: aggregate.driver.dispatch_tag_ids
      .map(id =>
        Object.assign(
          {},
          id,
          tags.find(f => f.id === id),
        ),
      )
      .map(t => t.name)
      .filter(Boolean)
      .join(', '),
    rating: aggregate.rating ? aggregate.rating.value : 0,
  };
}

export const riderDetailsSelector = createSelector(riderSelector, rider =>
  rider ? mapRiderAggregateToRiderVM(rider) : null,
);

function mapRiderAggregateToRiderVM(aggregate: RiderAggregate): TripRiderDetailsVM {
  return {
    id: aggregate.id,
    title: aggregate.rider?.first_name.concat(' ', aggregate.rider?.last_name),
    language: aggregate.rider?.language,
    email: aggregate.firebase_user?.email,
    phone: aggregate.firebase_user?.phone,
    createdAt: aggregate.rider?.created_at,
    workProfileReceiptEmail: aggregate.rider?.work_profile?.receipt_email,
    workProfileReceiptEmailDomain: aggregate.rider?.work_profile?.receipt_email_domain,
  };
}

export const tripVehicleDetailsSelector = createSelector(
  vehicleAggregateSelector,
  allProductFeatureListSelector,
  vehicleTypesSelector,
  allDispatchTagsSelector,
  (aggregate, features, vehicleTypes, tags) =>
    aggregate ? mapVehicleAggregateToVehicleDetails(aggregate, features, vehicleTypes, tags) : null,
);

function mapVehicleAggregateToVehicleDetails(
  aggregate: VehicleAggregate,
  features: ProductFeature[],
  vehicleTypes: VehicleType[],
  dispatchTags: DispatchTag[],
): TripVehicleDetailsVM {
  return {
    id: aggregate.id,
    title: [aggregate.vehicle.make, aggregate.vehicle.model].filter(Boolean).join(' '),
    digitaxDeviceId: aggregate.vehicle.digitax_device_id,
    licensePlate: aggregate.vehicle.license_plate,
    features: aggregate.vehicle.feature_ids
      .map(id =>
        Object.assign(
          {},
          id,
          features.find(f => f.id === id),
        ),
      )
      .map(t => t.name)
      .filter(Boolean)
      .join(', '),
    vehicleType: vehicleTypes.find(t => t.id === aggregate.vehicle.vehicle_type_id)?.name,
    posTerminalType: aggregate.vehicle.pos_terminal_type
      ? getPosTerminalType(aggregate.vehicle.pos_terminal_type)
      : null,
    dispatchTags: aggregate.vehicle.dispatch_tag_ids
      .map(id =>
        Object.assign(
          {},
          id,
          dispatchTags.find(f => f.id === id),
        ),
      )
      .map(t => t.name)
      .filter(Boolean)
      .join(', '),
  };
}

export const tripPartnerDetailsSelector = createSelector(partnerSelector, aggregate =>
  aggregate ? mapPartnerAggregateToPartnerDetails(aggregate) : null,
);

function mapPartnerAggregateToPartnerDetails(aggregate: PartnerCompanyAggregate): TripPartnerDetailsVM {
  return {
    id: aggregate.id,
    companyName: aggregate.company.name,
    ownerName: aggregate.company.owner.name,
    ownerEmail: aggregate.company.owner.email,
  };
}

function mapProductFeeToProductFeeVM(productFee: ProductFee[], productFeatures: ProductFeature[]): ProductFeeVM[] {
  return productFee
    ? productFee.map(fee => {
        const feature = productFeatures.find(m => m.id === fee.product_id);
        return {
          name: feature?.name,
          icon: feature?.icon,
          amount: fee.amount.display,
        };
      })
    : [];
}

function mapWaypoints(requested?: Location[], actual?: Location[]): Waypoint[] {
  return (
    requested?.map((w, index) => ({
      requested: getLocationAddress(w),
      actual: actual && actual[index] ? getLocationAddress(actual[index]) : null,
    })) ?? []
  );
}

function mapBookingAggrToTripPayments(payment: Payment): TripPaymentVM {
  return {
    bookingId: payment.booking_id,
    operations: payment.operations.map(f => ({
      reference: f.reference,
      amount: f.amount.value,
      amountDisplay: f.amount.display,
      type: f.type,
      paymentMethod: f.payment_method,
      executedAt: f.executed_at,
      source: f.source,
    })),
    totalAmount: payment.total_amount.display,
  };
}

function mapBookingAggrOffersToDriverIds(offers: Offer[]): string[] {
  return [...new Set(offers.filter(o => o.driver_id !== null).map(o => o.driver_id))];
}

function mapBookingNotesToNotesVM(notes: Note[]): NoteVM[] {
  return notes.map(n => ({
    body: n.body,
    createdAt: n.created_at,
  }));
}

function mapBookingAggrOffersToTripOffers(
  bookingId: string,
  offers: Offer[],
  drivers: DriverAggregate[],
): TripOfferVM[] {
  return offers
    .filter(m => m.status.type !== OfferStatusType.UNDEFINED)
    .map(o => {
      const stop = o.stops.find(s => s.booking_id === bookingId);
      const previousTripId = o?.stops.find(s => s.booking_id !== bookingId)?.booking_id;
      let date = null;
      let type = null;
      let driverName = o.driver_id;
      let actionCompletedIn = 0;

      const driverAggregate = drivers?.find(d => d.driver.id === o.driver_id);
      if (driverAggregate) {
        driverName = driverAggregate.driver.first_name + ' ' + driverAggregate.driver.last_name;
      }

      switch (o.status.type) {
        case OfferStatusType.ACCEPTED:
          date = o.accepted_at;
          type = 'Accepted';
          actionCompletedIn = o.assigned_at ? Math.round((o.accepted_at - o.assigned_at) / 1000) : 0;
          break;
        case OfferStatusType.ASSIGNED:
          date = o.assigned_at;
          type = 'Assigned';
          break;
        case OfferStatusType.CANCELLED:
          date = o.cancelled_at;
          type = 'Cancelled';
          actionCompletedIn = o.assigned_at ? Math.round((o.cancelled_at - o.assigned_at) / 1000) : 0;
          break;
        case OfferStatusType.REJECTED:
          date = o.rejected_at;
          type = 'Rejected';
          actionCompletedIn = o.assigned_at ? Math.round((o.rejected_at - o.assigned_at) / 1000) : 0;
          break;
        case OfferStatusType.EXPIRED:
          date = o.expired_at;
          type = 'Expired';
          actionCompletedIn = o.assigned_at ? Math.round((o.expired_at - o.assigned_at) / 1000) : 0;
          break;
      }

      return {
        id: o.id,
        driverId: o.driver_id,
        driverName,
        date,
        type,
        duration: stop.duration,
        distance: stop.distance,
        crowDistance: stop.crow_distance,
        actionCompletedIn,
        previousTripId,
      };
    });
}

function mapBookingAggrToLog(
  payment: TripPaymentVM,
  driverTip: DriverTip,
  paymentRefunds: BookingPaymentRefundVM[],
  compensations: CompensationVM[],
  tipRefunds: BookingPaymentRefundVM[],
  users: FirebaseUser[],
  failedCollectPaymentAt?: number,
): TripLogVM[] {
  const fullLog = [];

  payment?.operations?.forEach(operation => {
    if (operation.type !== OperationType.REFUNDED) {
      fullLog.push(formatPaymentOperationLog(operation, 'payment'));
    }
  });

  driverTip?.amount?.value && fullLog.push(formatTipLog(driverTip));

  paymentRefunds?.forEach(refund => fullLog.push(formatRefundLog(refund, RefundOperationType.PAYMENT, users)));

  tipRefunds?.forEach(refund => fullLog.push(formatRefundLog(refund, RefundOperationType.TIP, users)));

  compensations?.forEach(compensation => fullLog.push(formatCompensationLog(compensation, users)));
  if (failedCollectPaymentAt) {
    fullLog.push({ date: failedCollectPaymentAt, summary: 'Failed to collect payment' } as TripLogVM);
  }
  return fullLog;
}

function mapBookingAggrToAreaTimeZone(areas: Area[], areaId: string) {
  return areas.find(area => area.id === areaId)?.time_zone_id;
}

function formatPaymentOperationLog(operation: TripPaymentOperationVM, refundType: string): TripLogVM {
  // eslint-disable-next-line max-len
  let text = `${operation.amountDisplay} ${refundType} ${getOperationType(
    operation.type,
  )}. Paid by: ${getPaymentMethodType(operation.paymentMethod)}${operation.paymentMethod.variant ? ', variant: ' + getBookingPaymentMethodVariant(operation.paymentMethod.variant) : ''}`;
  if (operation.source) {
    text += `. Source: ${getPaymentOperationSource(operation.source)}`;
  }
  return {
    date: operation.executedAt,
    summary: text,
  };
}

function formatTipLog(tip: DriverTip) {
  return {
    date: DateTimeHelpers.numberOrTimestampToNumber(tip.completed_at),
    summary: `${tip.amount.display} tip paid by ${getPaymentMethodType(tip.payment_method)}`,
  };
}

function formatRefundLog(refund: BookingPaymentRefundVM, type: RefundOperationType, users: FirebaseUser[]) {
  const log = {} as TripLogVM;
  const refundOperationType = getRefundOperationType(type);
  const user = users.find(u => u.id === refund.createdBy)?.email;
  const displayUser = user ? user : refund.createdBy;
  if (refund.failedAt !== null) {
    log.date = refund.failedAt;
    log.summary = `${refund.amount} failed to refund by ${displayUser} with reason "${refund.failureReason}".`;
  } else if (refund.completedAt === null) {
    log.date = refund.createdAt;
    log.summary = `${refund.amount} ${refundOperationType} refund is in progress by ${displayUser} with reason "${refund.reason}".`;
  } else {
    log.date = refund.completedAt;
    log.summary = `${refund.amount} ${refundOperationType} refunded by ${displayUser} with reason "${refund.reason}".`;
  }
  return log;
}

function formatCompensationLog(compensation: CompensationVM, users: FirebaseUser[]): TripLogVM {
  const user = users.find(u => u.id === compensation.createdBy)?.email;
  const displayUser = user ? user : compensation.createdBy;
  let summary = `${compensation.amount} compensated by ${displayUser} with reason "${compensation.reason}"`;
  if (compensation.tag) {
    summary += ` and tag "${compensation.tag}"`;
  }
  summary += `.`;
  compensation.publicNote && (summary += ` Public note: "${compensation.publicNote}".`);
  return {
    date: compensation.createdAt,
    summary,
  };
}

function formatClaimLog(claim: TripClaimVM): TripLogVM {
  return {
    date: claim.claimedAt,
    summary: claim.driverId ? `Prebooking claimed by ${claim.driver}` : 'Prebooking unclaimed',
  };
}

function formatOfferLog(offer: TripOfferVM): TripLogVM {
  let actionCompletedInText = '';
  if (offer.actionCompletedIn > 0) {
    actionCompletedInText = ` in ${formatDuration(offer.actionCompletedIn)}`;
  }

  let summary = `Offer ${offer.type} ${actionCompletedInText}. Driver: ${offer.driverName}.`;
  summary += `<br /> ETA to pickup: <b> ${formatDuration(offer.duration)}. </b>`;
  if (offer.previousTripId) {
    // eslint-disable-next-line max-len
    summary +=
      `Dispatched as <b><a class='link-color' target='_blank' href='/support-agent/trips/` +
      offer.previousTripId +
      `'>trip on trip</a></b>`;
  }
  summary += `<br /> Distance: ${offer.distance}m. Crow distance: ${offer.crowDistance}m.`;

  return {
    date: offer.date,
    summary,
  };
}

function formatJobLog(job: BookingJob): TripLogVM {
  if (job.cancellation.reason === CancellationReason.OTHER) {
    return {
      date: job.cancelled_at,
      summary: `Cancelled due to Other reason with cancellation message: "${job.cancellation.message}"`,
    };
  }
  return {
    date: job.cancelled_at,
    summary: `Cancelled due to ${getCancellationReason(job.cancellation.reason)}`,
  };
}

function formatDistance(distance: number = 0): string {
  return (distance / 1000).toString().concat(' ', 'km');
}

export const searchResponseIdsSelector = createSelector(searchResponseSelector, response =>
  response ? mapSearchResponseHitsToList(response) : [],
);

function mapSearchResponseHitsToList(response: SearchResponse<TripsSearchResponse>): string[] {
  // eslint-disable-next-line no-underscore-dangle
  return response.hits?.hits?.map(hit => hit._id);
}

export const totalRecordsSelector = createSelector(searchResponseSelector, response =>
  response?.hits ? response.hits.total.value : 0,
);

export const tripListPageSize = createSelector(storagePageSizeSelector, userPageSizeSelector, (storage, state) =>
  !state ? (!storage ? 25 : storage) : state,
);

export const pageSelector = createSelector(queryParamsSelector, tripListPageSize, (params, pageSize) =>
  mapQueryParamsToPage(params, pageSize),
);

export const pageSizeSelector = createSelector(pageSelector, page => page.pageSize);

export const pageIndexSelector = createSelector(pageSelector, page => page.pageIndex);

export const searchFormSelector = createSelector(queryParamsSelector, userAreaIdsSelector, (params, areaIds) => {
  const searchForm = mapQueryParamsToSearchForm(params, areaIds);
  searchForm.tab = searchForm.tab || 'Trips';
  return searchForm;
});

export const isAdvancedFormSelector = createSelector(searchFormSelector, form => (form ? form.advanced : false));

export const tabSelector = createSelector(searchFormSelector, searchFrom => searchFrom?.tab);

export const driverIdSelector = createSelector(
  tripDriverIdSelector,
  tripOfferAcceptedDriverIdSelector,
  tripLastClaimSelector,
  (tripDriverId, offerDriverId, claim) =>
    tripDriverId ? tripDriverId : offerDriverId ? offerDriverId : claim ? claim.driver_id : null,
);

export const vehicleIdSelector = createSelector(
  tripVehicleIdSelector,
  tripAcceptedOfferVehicleIdSelector,
  (tripVehicleId, offerVehicleId) => (tripVehicleId ? tripVehicleId : offerVehicleId),
);

function formatDriverEta(eta: number): string {
  return 'ETA: ' + moment.duration(eta, 's').minutes().toString() + ' min';
}

function getArrivalAt(arrivals: DriverWait[]): number | null {
  const latest = [...arrivals].sort((a, b) => (a.completed_at < b.completed_at ? 1 : -1));
  return latest[0]?.completed_at
    ? moment(latest[0].completed_at.toDate()).subtract(latest[0].duration, 'seconds').valueOf()
    : null;
}

function getDriverFullName(
  trip: BookingTrip,
  tripListDrivers: TripListDriverVM[],
  drivers: DriverAggregate[],
  aggregate: BookingAggregate,
) {
  let driverFullName = trip?.driver != null ? trip.driver.first_name + ' ' + trip.driver.last_name : '';

  if (driverFullName) {
    return driverFullName;
  }

  const driverId = tripListDrivers.find(t => t.bookingId === aggregate.id)?.driverId;
  if (driverId) {
    const driver = drivers?.find(d => d.id === driverId)?.driver;
    if (driver) {
      driverFullName = [driver.first_name, driver.last_name].join(' ');
    }
  }

  return driverFullName;
}

function getVehicleLicensePlate(
  trip: BookingTrip,
  tripListDrivers: TripListDriverVM[],
  vehicles: VehicleAggregate[],
  aggregate: BookingAggregate,
) {
  let vehicleLicensePlate = trip?.vehicle?.license_plate;

  if (vehicleLicensePlate) {
    return vehicleLicensePlate;
  }

  const vehicleId = tripListDrivers.find(t => t.bookingId === aggregate.id)?.vehicleId;
  if (vehicleId) {
    vehicleLicensePlate = vehicles?.find(v => v.id === vehicleId)?.vehicle?.license_plate;
  }

  return vehicleLicensePlate;
}
