import { Params } from '@angular/router';
import { Action, createReducer, on } from '@ngrx/store';
import { BookingAggregate } from 'src/app/shared/models/booking.model';
import { FirebaseUser } from 'src/app/shared/models/firebase-user.model';
import { Quote } from 'src/app/shared/models/quote.model';
import { TripsActions } from '.';
import { AccountUser } from '../../../../../../src/app/shared/models/account-user.model';
import { Business } from '../../shared/models/business.model';
import { DriverAggregate, DriverEta, DriverTelemetry } from '../../shared/models/driver.model';
import { SearchResponse } from '../../shared/models/elastic-api.model';
import { PartnerCompanyAggregate } from '../../shared/models/partner.model';
import { RiderAggregate } from '../../shared/models/rider.module';
import { TripsSearchResponse } from '../../shared/models/trips.vm.model';
import { VehicleAggregate } from '../../shared/models/vehicle.model';

export interface SupportAgentTripsState {
  isLoading: boolean;
  isModalLoading: boolean;
  queryParams: Params;
  userPageSize: number;
  searchResponse: SearchResponse<TripsSearchResponse>;
  bookingId: string;
  bookingAggregate: BookingAggregate;
  quote: Quote;
  bookings: BookingAggregate[];
  drivers: DriverAggregate[];
  driverAggregate: DriverAggregate;
  driverUser: AccountUser;
  vehicleAggregate: VehicleAggregate;
  business: Business;
  users: FirebaseUser[];
  rider: RiderAggregate;
  claimsDrivers: DriverAggregate[];
  driverTelemetry: DriverTelemetry;
  driverEta: DriverEta;
  autoRefresh: boolean;
  partner: PartnerCompanyAggregate;
  vehicles: VehicleAggregate[];
}

export const initialState: SupportAgentTripsState = {
  isLoading: false,
  isModalLoading: false,
  queryParams: null,
  userPageSize: null,
  searchResponse: null,
  bookingId: null,
  bookingAggregate: null,
  quote: null,
  bookings: [],
  drivers: [],
  driverAggregate: null,
  driverUser: null,
  vehicleAggregate: null,
  business: null,
  users: [],
  rider: null,
  claimsDrivers: null,
  driverTelemetry: null,
  driverEta: null,
  autoRefresh: false,
  partner: null,
  vehicles: [],
};

export const tripReducer = createReducer(
  initialState,
  on(TripsActions.tripDetailsInit, (state, action) => ({
    ...state,
    bookingId: action.bookingId,
    bookingAggregate: null,
  })),
  on(TripsActions.queryParamsChanged, (state, action) => ({
    ...state,
    queryParams: action.queryParams,
    isLoading: true,
    bookings: [],
  })),
  on(TripsActions.pageChanged, (state, action) => ({
    ...state,
    userPageSize: action.pageEvent.pageSize,
  })),
  on(TripsActions.searchResponseFetched, (state, action) => ({
    ...state,
    searchResponse: action.searchResponse,
  })),
  on(TripsActions.bookingListFetched, (state, action) => ({
    ...state,
    bookings: action.bookings,
    isLoading: false,
  })),
  on(TripsActions.notCompletedTripListDriversFetched, (state, action) => ({
    ...state,
    drivers: action.drivers,
  })),
  on(TripsActions.tripListDestroyed, (state, _action) => ({
    ...state,
    queryParams: null,
    searchResponse: null,
    bookings: [],
    drivers: [],
    isLoading: false,
  })),

  on(TripsActions.bookingAggregateFetched, (state, action) => ({
    ...state,
    bookingAggregate: action.bookingAggregate,
    isLoading: false,
  })),
  on(TripsActions.bookingOfferDriversFetched, (state, action) => ({
    ...state,
    drivers: action.drivers,
  })),
  on(TripsActions.driverAggregateFetched, (state, action) => ({
    ...state,
    driverAggregate: action.driver,
  })),
  on(TripsActions.vehicleAggregateFetched, (state, action) => ({
    ...state,
    vehicleAggregate: action.vehicle,
  })),
  on(TripsActions.businessFetched, (state, action) => ({
    ...state,
    business: action.business,
  })),
  on(TripsActions.firebaseUsersFetched, (state, action) => ({
    ...state,
    users: action.users,
  })),
  on(TripsActions.quoteFetched, (state, action) => ({
    ...state,
    quote: action.quote,
  })),
  on(TripsActions.riderFetched, (state, action) => ({
    ...state,
    rider: action.rider,
  })),
  on(TripsActions.claimsDriversFetched, (state, action) => ({
    ...state,
    claimsDrivers: action.drivers,
  })),
  on(TripsActions.driverTelemetryFetched, (state, action) => ({
    ...state,
    driverTelemetry: action.telemetry,
  })),
  on(TripsActions.driverEtaFetched, (state, action) => ({
    ...state,
    driverEta: action.eta,
  })),
  on(TripsActions.autoRefreshChanged, (state, action) => ({
    ...state,
    autoRefresh: action.autoRefresh,
  })),
  on(TripsActions.autoRefreshTriggered, (state, _action) => ({
    ...state,
    isLoading: true,
  })),
  on(TripsActions.partnerFetched, (state, action) => ({
    ...state,
    partner: action.partner,
  })),
  on(TripsActions.driverUserFetched, (state, action) => ({
    ...state,
    driverUser: action.driverUser,
  })),
  on(TripsActions.tripDetailsDestroy, (state, _action) => ({
    ...state,
    bookingId: null,
    bookingAggregate: null,
    quote: null,
    drivers: null,
    driverAggregate: null,
    driverUserFetched: null,
    vehicleAggregate: null,
    business: null,
    users: [],
    rider: null,
    claimDriverAggregate: null,
    driverTelemetry: null,
    driverEta: null,
    partner: null,
  })),
  on(TripsActions.riderReceiptSubmitButtonClicked, (state, _action) => ({
    ...state,
    isModalLoading: true,
  })),
  on(TripsActions.riderReceiptCreated, (state, _action) => ({
    ...state,
    isModalLoading: false,
  })),
  on(TripsActions.riderReceiptCreateFailed, (state, _action) => ({
    ...state,
    isModalLoading: false,
  })),
  on(TripsActions.vehiclesFetched, (state, action) => ({
    ...state,
    vehicles: action.vehicles,
  })),
);

export function reducer(state: SupportAgentTripsState, action: Action) {
  return tripReducer(state, action);
}
